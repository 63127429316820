<template>
  <div
    class="alert"
    :class="[{ darker: props.darker }, props.theme]"
  >
    <slot />
    <UiComponentIcon
      theme="dark"
      type="x"
      @click="close"
    />
  </div>
</template>

<script setup>
// Emitter
const emit = defineEmits([
  'alert:close',
])

// Props
const props = defineProps({
  darker: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
})

// Methods
function close() {
  emit('alert:close')
}
</script>

<style lang="postcss" scoped>
.alert {
  @apply relative flex flex-col items-center justify-center w-full text-white text-origin text-center z-10;
  padding: 11px 28px;

  &.dark {
    @apply bg-winter-green-700;

    &.darker {
      @apply bg-winter-green-800;
    }
  }

  &.light {
    @apply bg-livestorm-blue-700;

    &.darker {
      @apply bg-livestorm-blue-800;
    }
  }

  @screen xl {
    @apply flex-row;
    padding: 11px 16px;
  }

  :deep(a) {
    @apply text-white;

    &.highlighted {
      @apply font-medium;
    }

    @screen md {
      @apply mt-0 mb-0 ml-6;
    }

    i {
      @apply text-white;
    }
  }

  i {
    @apply absolute top-0 right-0 flex items-center justify-center text-white cursor-pointer opacity-75 transform transition ease-out duration-300;
    height: 50px;
    width: 50px;

    &:hover {
      @apply -rotate-90 opacity-100;
    }
  }
}
</style>
